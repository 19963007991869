<template>
  <artmisads-modal
    :open="true"
    title="Billing Address"
    :width="720"
    :onCancel="() => props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="accountInfoModal">
        <div class="item">
          <a-form>
            <a-form-item
              name="contactName"
              v-bind="validateInfos.contactName"
            >
              <div class="input-item">
                <span class="text">Contact Name<span>*</span></span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('contactName')" size="middle" v-model:value="modelRef.contactName"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="country"
              v-bind="validateInfos.country"
            >
              <div class="input-item">
                <span class="text">Country<span>*</span></span>
                <artmisads-select
                  v-model:value="modelRef.country"
                  style="width: 324px;"
                  size="middle"
                  :options="props.countryList"
                />
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="phone"
              v-bind="validateInfos.phone"
            >
              <div class="input-item phone-item">
                <span class="phone-num">{{props.countryListMap[modelRef.country]?.countryPhoneCode}}</span>
                <span class="text">Phone<span>*</span></span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('phone')" size="middle" v-model:value="modelRef.phone"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="email"
              v-bind="validateInfos.email"
            >
              <div class="input-item">
                <span class="text">Email<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('email')" size="middle" v-model:value="modelRef.email"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="address"
              v-bind="validateInfos.address"
            >
              <div class="input-item">
                <span class="text">Address<span>*</span></span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('address')" size="middle" v-model:value="modelRef.address"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="addressCont"
              v-bind="validateInfos.addressCont"
            >
              <div class="input-item">
                <span class="text">Address Cont.</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('addressCont')" size="middle" v-model:value="modelRef.addressCont"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="city"
              v-bind="validateInfos.city"
            >
              <div class="input-item">
                <span class="text">City<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('city')" size="middle" v-model:value="modelRef.city"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="state"
              v-bind="validateInfos.state"
            >
              <div class="input-item">
                <span class="text">State<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('state')" size="middle" v-model:value="modelRef.state"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="zipCode"
              v-bind="validateInfos.zipCode"
            >
              <div class="input-item">
                <span class="text">Zip Code<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('zipCode')" size="middle" v-model:value="modelRef.zipCode"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button type="primary" @click="sellerBillingAddressSave">Save</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted } from "vue";
import { connectionApi } from "@/server/seller/index";

const props = defineProps(["addressInfo", 'setShowModal', 'countryList', 'countryListMap']);
const spinning = ref(false);

const useForm = Form.useForm;
  const modelRef = reactive({
    contactName: props.addressInfo.contactName,
    country: props.addressInfo.country,
    phone: props.addressInfo.phone,
    email: props.addressInfo.email,
    address: props.addressInfo.address,
    addressCont: props.addressInfo.addressCont,
    city: props.addressInfo.city,
    state: props.addressInfo.state,
    zipCode: props.addressInfo.zipCode,
  });

  const rulesRef = reactive({
    addressCont: [{
      required: false,
    }],
    contactName: [{
      required: true,
      message: 'Please fill in your contact name',
      trigger: ['blur']
    }],
    country: [{
      required: true,
      message: 'Please fill in your country',
      trigger: ['blur']
    }],
    phone: [{
      required: true,
      message: 'Please fill in your phone',
      trigger: ['blur']
    }],
    email: [{
      required: true,
      message: 'Please fill in your email',
      trigger: ['blur']
    }],
    address: [{
      required: true,
      message: 'Please fill in your address',
      trigger: ['blur']
    }],
    city: [{
      required: true,
      message: 'Please fill in your city',
      trigger: ['blur']
    }],
    state: [{
      required: true,
      message: 'Please fill in your state',
      trigger: ['blur']
    }],
    zipCode: [{
      required: true,
      message: 'Please fill in your zip code',
      trigger: ['blur']
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

const sellerBillingAddressSave = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = validateRes;
    const res = await await connectionApi.sellerBillingAddressSave(paramsData);
    const { success } = res;
    if (success) {
      props.setShowModal(false, true);
    }
    spinning.value = false;
  }
};

</script>

<style lang="less" scoped>
.accountInfoModal {
  padding-bottom: 56px;
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    :deep(.ant-form) {
      .input-item {
        display: flex;
        flex-direction: column;
        width: 324px;
        .text {
          color: var(--dark-2);
          font-weight: 500;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 4px;
          display: flex;
          > span {
            color: var(--red-text);
            margin-left: 3px;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
      .phone-item {
        position: relative;
        input {
          padding-left: 50px;
        }
        .phone-num {
          position: absolute;
          left: 10px;
          top: 23px;
          z-index: 1;
          font-size: 13px;
          font-weight: 500;
          line-height: 32px;
          color: var(--dark-2);
          letter-spacing: 0.4px;
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
