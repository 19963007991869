<template>
  <div class="account-info">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-a-shezhi2"></use>
        </svg>
      </breadcrumbs>
      <div class="content">
        <div class="account-wrap">
          <accountInfoTemp
            :accountInfo="accountInfo"
            :getData="getData"
            :countryList="countryList"
            :countryListMap="countryListMap"
          />
          <div class="im-wrap">
            <imContactInfoTemp
              :userImInfo="userImInfo"
              :getData="getData"
            />
          </div>
        </div>
        <div class="address-wrap">
          <addressInfoTemp
            :addressInfo="addressInfo"
            :getData="getData"
            :countryList="countryList"
            :countryListMap="countryListMap"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script setup>
  import { keyBy } from 'lodash';
  import { ref, onMounted, watch } from "vue";
  import { connectionApi } from "@/server/seller/index";
  import breadcrumbs from "../../breadcrumbs.vue";
  import accountInfoTemp from './accountInfoTemp.vue';
  import addressInfoTemp from './addressInfoTemp.vue';
  import imContactInfoTemp from './imContactInfoTemp.vue';
  const breadcrumbsData = {
    title: "Account Info",
    color: "#776D91",
    links: [
      {
        name: "Home",
        path: "/seller/dashboard",
      },
      {
        name: "Settings",
        path: null,
      },
      {
        name: "Account Info",
        path: "/seller/account-info",
      },
    ],
  };
  const spinning = ref(false);
  const userImInfo = ref({});
  const countryList = ref([]);
  const countryListMap = ref({});
  const accountInfo = ref({});
  const addressInfo = ref({});

  const getData = async () => {
    spinning.value = true;
    const accountInfoRes = await connectionApi.getSellerAccountInfoGetAccountInfo();
    const addressInfoRes = await connectionApi.getSellerBillingAddressGetBillingAddressInfo();
    const countryListRes = await connectionApi.getSellerAccountInfoGetCountry();
    const userImRes = await connectionApi.getUserIm();
    
    if (addressInfoRes.success) {
      addressInfo.value = addressInfoRes.data;
    }
    if (accountInfoRes.success) {
      accountInfo.value = accountInfoRes.data;
    }
    if (countryListRes.success) {
      const listData = countryListRes.data.map(item => {
        item.label = item.englishName
        item.value = item.countryCode;
        return item;
      });
      countryList.value = listData;
      countryListMap.value = keyBy(listData, 'countryCode');
    }
    if (userImRes.success) {
      userImInfo.value = userImRes.data || {};
    }
    spinning.value = false;
  };

  onMounted(() => {
    getData();
  })

</script>
<style lang="less" scoped>
  .account-info {
    padding: 0 16px;
    .content {
      display: flex;
      .account-wrap {
        width: 50%;
      }
      .address-wrap {
        width: 50%;
        margin-left: 16px;
      }
      .im-wrap {
        margin-top: 16px;
      }
    }
  }
</style>
