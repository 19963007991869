<template>
  <artmisads-modal
    :open="true"
    title="Account Information"
    :width="720"
    :onCancel="() =>  props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="accountInfoModal">
        <div class="item">
          <a-form>
            <a-form-item
              name="accountName"
              v-bind="validateInfos.accountName"
            >
              <div class="input-item">
                <span class="text">Account Name</span>
                <artmisads-input disabled maxlength="100" style="width: 324px;" @blur="() => validate('accountName')" size="middle" v-model:value="modelRef.accountName"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="dbaName"
              v-bind="validateInfos.dbaName"
            >
              <div class="input-item">
                <span class="text">DBA Name</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('dbaName')" size="middle" v-model:value="modelRef.dbaName"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="country"
              v-bind="validateInfos.country"
            >
              <div class="input-item">
                <span class="text">Country<span>*</span></span>
                <artmisads-select
                  v-model:value="modelRef.country"
                  style="width: 324px;"
                  size="middle"
                  :options="props.countryList"
                />
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="operationContactNumber"
              v-bind="validateInfos.operationContactNumber"
            >
              <div class="input-item">
                <span class="text">Operation Contact Number<span>*</span></span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('operationContactNumber')" size="middle" v-model:value="modelRef.operationContactNumber"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="operationContactEmail"
              v-bind="validateInfos.operationContactEmail"
            >
              <div class="input-item">
                <span class="text">Operation Contact Email<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('operationContactEmail')" size="middle" v-model:value="modelRef.operationContactEmail"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="taxRegistrationNumber"
              v-bind="validateInfos.taxRegistrationNumber"
            >
              <div class="input-item">
                <span class="text">Tax Registration Number<span>*</span></span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('taxRegistrationNumber')" size="middle" v-model:value="modelRef.taxRegistrationNumber"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button type="primary" @click="sellerAccountInfoSave">Save</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted } from "vue";
import { connectionApi } from "@/server/seller/index";

const props = defineProps(["accountInfo", 'setShowModal', 'countryList']);
const spinning = ref(false);

const useForm = Form.useForm;
  const modelRef = reactive({
    accountName: props.accountInfo.accountName,
    dbaName: props.accountInfo.dbaName,
    country: props.accountInfo.country,
    operationContactNumber: props.accountInfo.operationContactNumber,
    operationContactEmail: props.accountInfo.operationContactEmail,
    taxRegistrationNumber: props.accountInfo.taxRegistrationNumber,
  });

  const rulesRef = reactive({
    accountName: [{
      required: false,
    }],
    dbaName: [{
      required: false,
    }],
    country: [{
      required: true,
      message: 'Please fill in your country',
      trigger: ['blur']
    }],
    operationContactNumber: [{
      required: true,
      message: 'Please fill in your operation contact number',
      trigger: ['blur']
    }],
    operationContactEmail: [{
      required: true,
      message: 'Please fill in your operation contact email',
      trigger: ['blur']
    }],
    taxRegistrationNumber: [{
      required: true,
      message: 'Please fill in your tax registration number',
      trigger: ['blur']
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

const sellerAccountInfoSave = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = validateRes;
    const res = await await connectionApi.sellerAccountInfoSave(paramsData);
    const { success } = res;
    if (success) {
      props.setShowModal(false, true);
    }
    spinning.value = false;
  }
};

</script>

<style lang="less" scoped>
.accountInfoModal {
  padding-bottom: 56px;
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    :deep(.ant-form) {
      .input-item {
        display: flex;
        flex-direction: column;
        width: 324px;
        .text {
          color: var(--dark-2);
          font-weight: 500;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 4px;
          display: flex;
          > span {
            color: var(--red-text);
            margin-left: 3px;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
