<template>
  <div class="account">
    <div class="head">
      <p> Billing Address<span v-if="!props.addressInfo.phone">*</span></p>
      <artmisads-select-button @click="() => setShowModal(true)" size="middle" :showEditIcon="true">Edit</artmisads-select-button>
    </div>
    <div class="cont">
      <div class="item">
        <div class="item-left">
          <span class="name">Contact Name:</span>
          <span class="text" v-if="props.addressInfo.contactName">{{props.addressInfo.contactName}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">Country:</span>
          <span class="text" v-if="props.addressInfo.country">{{props.countryListMap[props.addressInfo.country]?.label}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          <span class="name">Phone:</span>
          <span class="text" v-if="props.addressInfo.phone">{{props.addressInfo.phone}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">Email:</span>
          <span class="text" v-if="props.addressInfo.email">{{props.addressInfo.email}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          <span class="name">Address:</span>
          <span class="text" v-if="props.addressInfo.address">{{props.addressInfo.address}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">Address Cont.:</span>
          <span class="text" v-if="props.addressInfo.addressCont">{{props.addressInfo.addressCont}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          <span class="name">City:</span>
          <span class="text" v-if="props.addressInfo.city">{{props.addressInfo.city}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">State:</span>
          <span class="text" v-if="props.addressInfo.state">{{props.addressInfo.state}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          <span class="name">Zip Code:</span>
          <span class="text" v-if="props.addressInfo.zipCode">{{props.addressInfo.zipCode}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
    </div>
    <editAddressInfoModal
      :addressInfo="props.addressInfo"
      :setShowModal="setShowModal"
      :countryList="countryList"
      :countryListMap="countryListMap"
      v-if="showModal"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
  import editAddressInfoModal from './editAddressInfoModal.vue';
  const props = defineProps(['addressInfo', 'getData', 'countryListMap', 'countryList'])

  const showModal = ref(false);

  const setShowModal = (status, reload) => {
    showModal.value = status;
    if (reload) {
      props.getData()
    }
  }
</script>
<style lang="less" scoped>
  .account {
    padding: 16px 0 24px 16px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
    .head {
      padding: 0 16px 16px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-4);
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
        > span {
          color: var(--red-text);
          margin-left: 3px;
          font-size: 16px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
    .cont {
      padding-right: 16px;
      .item {
        display: flex;
        margin-top: 24px;
      }
      .item-right {
        margin-left: 16px;
      }
      .item-right,
      .item-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 400;
          font-size: 13px;
          color: var(--dark-3);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
        .text {
          font-weight: 600;
          font-size: 14px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
          word-break: break-all;
        }
        .no-data {
          font-weight: 500;
          font-size: 14px;
          color: var(--dark-4);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
  }
</style>
